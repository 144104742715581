import { popupsVariables, init as popupsInit, getTop } from './popups.js';
import { GenericPopUpClose, GenericPopUpInit } from "./genericPopup.js";

const init = () => {
    GenericPopUpInit();

    // youtube embed
    $('a[href*="youtube.com/embed/"]').each(function() {
        var link = $(this);
        link.off('click').on('click', function() {
            onClick(link.attr('href') + '?rel=0&autoplay=1');
            return false;
        });
    });

    // youtube video
    $('a[href*="youtube.com/watch?v="]').each(function() {
        var link = $(this);
        link.off('click').on('click', function() {
            onClick('//www.youtube.com/embed/' + link.attr('href').split('?v=')[1] + '?rel=0&autoplay=1');
            return false;
        });
    });

    // vimeo link
    $('a[href^="https://vimeo.com/"]').each(function() {
        var link = $(this);
        link.off('click').on('click', function() {
            var src = link.attr('href').split('/');
            onClick('//player.vimeo.com/video/' + src[src.length - 1] + '?autoplay=1&color=ffffff&title=0&byline=0&portrait=0');
            return false;
        });
    });

    $('.popupFade-new').off('click', GenericPopUpClose).on('click', GenericPopUpClose);
}

const onClick = (src) => {
    if (!popupsVariables['allow'] || window.site.win.width() < popupsVariables['limit'] || popupsVariables['backTo']) {
        window.open(src);
        return;
    }

    $.ajax({
        type: 'GET',
        url: `/VideoPopup?src=${src}`,
        cache: false,
        success: function(response) {
            window.site.body.append(response);
            var p = $('#popupVideo');
            p.css('top', getTop());
            popupsInit();
            p.add('#popupFade').show();
            popupsVariables['allow'] = true;
        }
        // Error condition here to be addressed in ST-4624
    });
}

export default init;
